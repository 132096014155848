<template>
 <div class="common-layout">

      <div class="home">
        <img src="@/img/homepage.png" />
      </div>
      <!-- 主要内容 -->
<div class="main_zone">
<div class="type_area">
  <!-- 业务范畴 -->
  <div class="category">
    <div class="headline" >
      <div  >
        <span class="underline">业务范畴</span>
        <!-- <div class="line"></div> -->
      </div>
   <div class="subtitle">定制开发推动您的业务迈向未来</div>
    </div>
     <div class="details">
      <div class="left">
        <img src="@/img/buildaPlatform.png" style="width:80px;height:80px;display: block; margin: 0 auto;  margin-top:28px;margin-bottom: 44px;"/>
        <div class="textlist" >
          <div class="textone">搭建平台</div>
         <ul>
         <li>搭建和运营互联网服务平台</li>
         <li>为国内各大保险公司提供企业产品宣传、车务增值服务等产品内容</li>
          <li>围绕车险行业，提供完整车险生态服务解决方案</li>
         </ul>
        </div>
      </div>
  <div class="middle">
    <img src="@/img/autoInsurance.png" style="width:80px;height:80px;display: block; margin: 0 auto;  margin-top:28px;margin-bottom: 44px;"/>
        <div class="textlist">
          <div class="textone">非车险服务</div>
         <ul>
          <li>通过孵化行业项目，促进非车险2B行业生态产品、服务及运营创新</li>
         </ul>
        </div>
  </div>
  <div class="right" >
    <img src="@/img/Vehicleservice.png" style="width:80px;height:80px;display: block; margin: 0 auto;  margin-top:28px;margin-bottom: 44px;"/>
        <div class="textlist">
          <div class="textone">车务服务</div>
         <ul>
          <li>为全国私家车主提供“代办年检”等汽车后市场全产业链服务</li>
          <li>通过自研IVI智能系统，为汽车后市场中小企业提供平台运营及门店引流服务，从而达到互惠互利</li>
         </ul>
        </div>
  </div>

     </div>
      </div>
      <!-- 我们的优势 -->
  <div class="our_advantages">
    <div class="headline" >
   <div class="underline" >我们的优势
   </div>
   <div class="subtitle">专业+资金+服务</div>
    </div>
    <section class="layout">
  <div class="grow1">
  <p  class="piccap">专业高效</p>
  <p  class="Image_content">专业的方案输出，加上研发和售后团队共同的支持，实现高效对接，高效落地。</p>
  </div>
  <div class="grow1">
    <p  class="piccap">安全稳健</p>
    <p  class="Image_content">多个子系统，可以灵活支持各种业务变化，自动化测试和部署，同时节省95%以上的维护人力成本，安全快捷稳健。</p> 
  </div>
  <div class="grow1">
    <p  class="piccap">行业领先</p>
    <p class="Image_content">在线运营服务领域涉及全国，已建立多家分支机构，服务于平安、人保、太保等top10企业。</p> 
  </div>
</section>
  </div>
  <!-- 合作伙伴 -->
  <div class="cooperative_partner">
    <div class="headline" >
   <div class="underline" >合作伙伴</div>
   <div class="subtitle">合作共赢，共幸携手合作金融伙伴共同成长</div>
    </div>
    <section class="partner">
      <div class="partner_list">
        <img src="@/img/logs/PingAnInsurance.png" style="width:260px;height:64px"/></div>
        <div class="partner_list" style="  background: #FAFAFC;box-shadow: inset -2px -2px 20px rgba(49, 66, 222, 0.08), inset 2px 2px 20px rgba(49, 66, 222, 0.08);">
        <img src="@/img/logs/Easyjet.png" style="width:137px;height:78px"/></div>
        <div class="partner_list">
        <img src="@/img/logs/peoples_insurance.png" /></div>
        <div class="partner_list" style="  background: #FAFAFC;box-shadow: inset -2px -2px 20px rgba(49, 66, 222, 0.08), inset 2px 2px 20px rgba(49, 66, 222, 0.08);">
        <img src="@/img/logs/Peoplepropertyinsurance.png" /></div>
        <div class="partner_list" style="  background: #FAFAFC;box-shadow: inset -2px -2px 20px rgba(49, 66, 222, 0.08), inset 2px 2px 20px rgba(49, 66, 222, 0.08);">
        <img src="@/img/logs/SunshineInsurance.png" /></div>
        <div class="partner_list">
        <img src="@/img/logs/Landpropertyinsurance.png" /></div>
        <div class="partner_list" style="  background: #FAFAFC;box-shadow: inset -2px -2px 20px rgba(49, 66, 222, 0.08), inset 2px 2px 20px rgba(49, 66, 222, 0.08);">
        <img src="@/img/logs/tpibuilding.png" /></div>
        <div class="partner_list">
        <img src="@/img/logs/TaikangLifeInsurance.png" /></div>
    </section>
  </div>
</div>
</div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    
  }
}
</script>

<style scoped>
/* 大盒子 */
.common-layout{
  width:100%;
}
/* 图片 */
 .home{
   overflow: hidden;
   width: 100%;
 }
 .home >img {
   width: 100%;
   height:680px;
   object-fit: cover; /* 避免图片变形 */
     display: block;
   margin: 0;
   padding: 0;
   border: none;
 }

/* 主要内容 */
.main_zone{
  width:100%;
  height: 1901px;
}
/* 版心  */
/* .type_area{
width: 1200px;
height: 100%;
} */
.category{
  height:656px;
  color: #FFFFFF;
}
.headline{
  /* width:336px; */
  height: 89px;
  margin: 0 auto;
  text-align: center; 
  margin-bottom: 60px;
  padding-top: 60px;
}
/* 调整下划线位置 */
.underline {
  display: inline-block;
  background: linear-gradient(145.43deg, rgba(255, 175, 155, 0.9) 10.56%, rgba(241, 79, 68, 0.9) 91.33%);
  background-repeat: no-repeat;
  background-size: 80% 8px; /* 调整下划线的长度和厚度 */
  background-position: bottom;
  margin-bottom: 20px;
  color: #333333;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 37px;
}
.subtitle{
  color: #656565;
  font-family: 'Microsoft YaHei';
font-style: normal;
font-weight: 400;
font-size: 24px;
line-height: 32px;
}
.details{
  width: 1200px;
  height:384px;
  overflow: hidden;
  margin: 0 auto;
}
.left {
  width: 384px;
  height: 387px;
  float: left;
  /* margin-right: 24px; */
  margin: 0 auto;
  background: #FAFAFA;
}
.textone{
  display: flex;
  justify-content: center;
  font-family: 'Microsoft YaHei';
font-style: normal;
font-weight: 400;
font-size: 20px;
color: #292C2C;
line-height: 26px;
margin-bottom: 24px;
}
ul{
  margin: 28px 28px;
  list-style: none;
}
li{
   position: relative;
  font-family: 'Microsoft YaHei';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 180%;
align-items: center;
color: #545454;
padding-bottom: 12px;
}

li::before{
content: ""; 
  display: inline-block;
  width: 10.5px;
  margin-right: 8px; 
  height: 10.5px;
  border-radius: 50%;
  background-image: linear-gradient(145.43deg, #FFAF9B 10.56%, #F14F44 91.33%);
}
.middle {
  height: 387px;
  width: 384px;
 margin-left: 24px;
  float: left;
  background: #FAFAFA;
}

.right {
  width: 384px;
  height: 387px;
  float: left;
  margin-left: 24px;
  background: #FAFAFA;
}
/* 我们的优势 */
.our_advantages{
  height:589px;
  background: #FAFAFA;
}
.layout {
  width: 1200px;
  display: flex;
  gap: 16px;
  overflow: hidden;
  margin: 0 auto;
}
.grow1 { 
  flex-grow: 1; 
  width: 384px;
height: 320px;
}
.grow1:nth-of-type(1) {
  background:linear-gradient(180deg, rgba(0, 0, 0, 0.19) 0%, #000000 100%), url('@/img/patentlicensing.png');
 object-fit: cover;
}
.grow1:nth-of-type(2) {
  background:linear-gradient(180deg, rgba(0, 0, 0, 0.19) 0%, #000000 100%), url('@/img/safetyandsoundness.png');
 object-fit: cover;
}
.grow1:nth-of-type(3) {
  background:linear-gradient(180deg, rgba(0, 0, 0, 0.19) 0%, #000000 100%), url('@/img/industryleader.png');
 object-fit: cover;
}
.piccap{
padding: 135px 0 0 20px;
font-size: 20px;
line-height: 40px;
color: #FFFFFF;
}
.Image_content{
  font-family: 'Microsoft YaHei';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 180%;
color: #EEEEEE;
padding: 0 39px 0 20px;
}
/* 合作伙伴 */
.cooperative_partner{
  height: 656px;
  background-color:#FFFFFF;
}
 .partner {
  width: 1200px;
  height:360px;
  overflow: hidden;
  margin: 0 auto;
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(4, 1fr);
}
.partner_list{
  display: flex; /* 使用 Flexbox 布局 */
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
}
.partner_list>img{
  display: block; /* 去除图片默认的 inline 属性 */
  margin: auto; /* 使图片在盒子中水平居中 */
  width: 260px;
  height: 66px;
  object-fit: cover;
}
</style>
