<template>
  <div class="wrapper">
    <div class="title">
      <div class="logo">
        <img class="img" src="./img/logo.png" />
      </div>
      <!-- tabs -->
      <div class="tabslist">
        <el-tabs v-model="activeTab" class="demo-tabs" @tab-click="handleTabClick" style="width: 100%">
          <el-tab-pane label="首页" name="first"></el-tab-pane>
          <el-tab-pane label="产品" name="second"></el-tab-pane>
          <el-tab-pane label="服务" name="third"></el-tab-pane>
          <el-tab-pane label="关于我们" name="fourth"></el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <router-view></router-view>
    <!-- 底部 -->
    <div class="base">
      <div class="contact_way">
        <section class="details_list">
          <div class="intro">
            <p style="color: #292c2c;font-size: 20px;line-height: 26px;font-style: normal;font-weight: 700;">
              联系方式
            </p>
            <ul style="list-style: none">
              <li class="intro_list">
                地址：深圳市南山区科发路19号南山金融大厦10D
              </li>
              <li class="intro_list">联系电话：26998985</li>
              <li class="intro_list">邮箱：admin@go-x.cn</li>
              <!-- <li class="intro_copy" style="">
                Copyright @ 2023 共幸科技（深圳）有限公司 粤ICP备20066053号-1
              </li> -->
            </ul>
          </div>
          <div class="qr_code">
            <section class="qr_codelist">
              <div>
                <img src="./img/logo.png" style="width: 200px;height: auto;display: block;float: right;" /><br />
              </div>
              <div>
                <img src="./img/QRcode.png" style="width: 120px;height: 120px;display: block;float: right;padding-right: 20px;" />
              </div>
              
            </section>
          </div>
        </section>
      </div>
      
    </div>
    <div class="into_bottom">
      <div class="into_bottom_box">
        <div class="details_list">
        <div class="intro_copy2" style=" font-size: 16px; color: #292c2c">Copyright @ 2023 共幸科技（深圳）有限公司 <a href="https://beian.miit.gov.cn" target="_blank">粤ICP备20066053号-3</a></div>
        <!-- <div class="ssl" style="width:100%; text-align: right;">
                <a href="https://xyt.xcc.cn/getpcInfo?sn=1767145446976761856&language=CN&certType=8&url=*.gongxingtech.com" target="_blank" style="position: relative;display: inline-block;height: 38px;padding-right: 30px;">
                <div style="width:100%;height:100%;position: absolute;top: 0;left: 0;"></div>
                <embed src="https://program.xinchacha.com/web/1767145446976761856=*.gongxingtech.com.svg" width="103" height="38" type="image/svg+xml" pluginspage="//www.adobe.com/svg/viewer/install/"/>
              </a>
              </div> -->
      </div>
    </div>    
    </div>
  </div>
</template>
<script >
import { onBeforeRouteUpdate } from "vue-router";
export default {
  data() {
    return {
      activeTab: "first",
    };
  },
  mounted() {
    this.updateActiveTab(); // 初始化时更新 activeTab
  },
  watch: {
    $route(to, from) {
      this.updateActiveTab(); // 路由变化时更新 activeTab
    },
  },
  methods: {
    handleTabClick(tab) {
      const tabName = tab.props.name;
      //  console.log(tabName)
      if (tabName === "first") {
        this.$router.push("/");
      } else if (tabName === "second") {
        this.$router.push("/Products");
      } else if (tabName === "fourth") {
        this.$router.push("/AboutUs");
      } else if (tabName === "third") {
        this.$router.push("/sustain");
      }
    },
    updateActiveTab() {
      const path = this.$route.path;
      if (path === "/") {
        this.activeTab = "first";
      } else if (path === "/Products") {
        this.activeTab = "second";
      } else if (path === "/AboutUs") {
        this.activeTab = "fourth";
      } else if (path === "/sustain") {
        this.activeTab = "third";
      } else {
        this.activeTab = "";
      }
    },
  },
};
</script>
  
<style sass>
.wrapper {
  min-height: 900px;
  display: flex;
  flex-direction: column;
}
.qr_codelist {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.intro_list {
  font-family: "Microsoft YaHei";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  padding-top: 12px;
  color: #292c2c;
}

.title {
  width: 1200px;
  height: 75px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}


/* 图片盒子 */
.logo {
  display: flex;
  /* 使用 Flexbox 布局 */
  justify-content: center;
  /* 水平居中 */
  align-items: center;
  /* 垂直居中 */
  overflow: hidden;
}

.img {
  display: block;
  /* 去除图片默认的 inline 属性 */
  margin: auto;
  /* 使图片在盒子中水平居中 */
  width: 200px;
  height: auto;
}

/* tabs的布局 */
.tabslist {
  display: flex;
  /* 使用 Flexbox 布局 */
  justify-content: center;
  /* 水平居中 */
  align-items: center;
  /* 垂直居中 */
}

.demo-tabs {
  padding-left: 50px;
  height: 51px;
}

/* el-tabs选中下划线 */
.el-tabs__active-bar {
  background: linear-gradient(145.43deg, rgba(255, 175, 155, 0.9) 10.56%, rgba(241, 79, 68, 0.9) 91.33%) !important;
  height: 8px !important;
  /* 调整下划线的高度 */
  bottom: 16% !important;
  /* 将下划线定位到选项卡底部 */

}

/* 改变 element的el-tabs默认选中项 文字颜色 */
.el-tabs__item {
  /* color: #000 !important; */
  font-size: 18px !important;
  padding: 0 40px !important;
}

/* 最后一个标签没有右侧间距 */
.el-tabs__item:last-child {
  padding-right: 0 !important;
}

/* 点击的标签的高亮 */
.el-tabs__item.is-active {
  color: var(--unnamed, #292C2C) !important;
  position: relative !important;
  z-index: 1 !important;
  /* 设置选项卡字体的堆叠顺序，使其覆盖在下划线上方 */
}

.el-tabs:hover {
  --el-color-primary: #F14F44 !important;
}
/*去掉tabs底部的下划线*/
.el-tabs__nav-wrap::after {
  position: static !important;
}
.page-title {
  display: flex;
  align-items: center;
}
.img-and-tabs {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* 可选：垂直居中 */
}
.title-and-tabs {
  width: 100%;
  display: flex;
}
.base {
  justify-content: center;
  /* padding:60px 0; */
  /* align-items: center; */
  width: 100%;
  height: 300px;
  background: #fafafa;
  display: flex;
  /* margin-top:30px; */
}
.contact_way {
  width: 1200px;
  /* height:294px; */
  flex-shrink: 0;
  top: 50%;
  padding: 60px 0;
}
.details_list {
  height: 100%;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px 0px;
}
.intro_copy {
  padding-top: 100px; font-size: 16px; color: #292c2c
}
.into_bottom {
  justify-content: center;
  width: 100%;
  height: 80px;
  background: #fafafa;
  display: flex;
  /* padding-top: 50px; */
}
.into_bottom_box {
  width: 1200px;
  flex-shrink: 0;
}
@media screen and (max-width: 1700px) {
  .into_bottom {
    padding-top: 50px;
  }
}
@media screen and (mix-width: 1701px) {
  .into_bottom {
    padding-top: 20px;
  }
}
/* @media screen and (max-width: 1700px) {
  .intro_copy {
    padding-top: 120px;
  }
}
@media screen and (max-width: 1700px) {
  .ssl {
    text-align: right;
    margin-top: 30px;
    padding-right: 30px;
  }
}
@media screen and (min-width: 1701px) and (max-width: 1800px) {
  .ssl {
    text-align: right;
    margin-top: 30px;
    padding-right: 30px;
  }
}
@media screen and (min-width: 1801px) {
  .ssl {
    text-align: right;
    margin-top: 40px;
    padding-right: 30px;
  }
} */
</style>
  