<template>
  <div class="common-layout">
 
       <div class="home">
         <img src="@/img/product.png" />
       </div>
       <!-- 主要内容 -->
       <div class="navigation">
        您当前位置：
        <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }" class="myColor">共幸科技</el-breadcrumb-item>
      <el-breadcrumb-item class="myColor" >产品中心</el-breadcrumb-item>
    </el-breadcrumb> 
</div>
 <div class="main_zone" style="height:1820px;">
  <!-- 九华保 -->
  <div class="product_display">
    <el-container >
        <el-header>
        <i  class="headline" style="width:8px;height: 28px;background: linear-gradient(145.43deg, #FFAF9B 10.56%, #F14F44 91.33%);display:inline-block;margin-right: 12px;"></i>
        <div class="icon">自有产品</div>
      </el-header>
      <div class="official_accounts">九华保</div>
      <el-container>
        <el-aside >
          <img src="@/img/product_picture/Jiuhuashan.png" style="width:160px;height:160px;display: block;margin: auto;object-fit: cover;padding-bottom: 48px;"/>
       <div style="width: 48px;border: 1px solid #B5B5B5;margin: 0 auto;"></div>
         <span class="explain">&nbsp;&nbsp; 九华保”作为共幸科技旗下品牌，其一直致力于打造资源整合平台，基于S2B2C的商业模式，公司连接了保险公司、互联网公司、银行与线上线下商户资源，在全国多个省市自治区设立分支机构，为全国车主用户提供集洗车、加油、代驾、年检代办等为一体的线上线下服务体系，成为车主信息服务生态中不可或缺的一环。<br/>
           &nbsp;&nbsp;“九华保”名称来源于四大佛教圣地之一九华山，取地藏王菩萨：安忍不动如大地，静虑深密如秘藏之意。</span>
        </el-aside>
        <el-main>
        <img src="@/img/product_picture/gzh_homepage.png" />
        <img src="@/img/product_picture/gzh_As.png" />
        <img src="@/img/product_picture/telephone_charge.png" />
        </el-main>
      </el-container>
    </el-container>
  </div>
  <!-- 九华保产品 -->
<div class="category">
  <div class="official_accounts">九华保产品</div>
<div class="category_list" style="margin-bottom:24px;">
  <div class="product_class"><img src="@/img/product_picture/car_wash.png"/>特惠洗车</div>
  <div class="text_description">享受优质洗车服务，提供品质保障。洗车服务提供精准推送，并采用在线预约，到店核销的方式，简单便捷。</div>
</div>
<div class="category_list" style="margin-bottom:24px;">
  <div class="product_class"><img src="@/img/product_picture/cheer.png"/>特惠加油</div>
  <div class="text_description">享加油券优惠套餐，直营店可用，方便快捷，安全可靠，支持权益、现金组合支付。</div>
</div>
<div class="category_list" style="margin-bottom:24px;">
  <div class="product_class"><img src="@/img/product_picture/equity.png"/>权益</div>
  <div class="text_description">对客户的回馈活动。1权益=1元，可申请兑换成等值的人民币；也可在平台消费，购买洗车券、充值话费、等产品服务。</div>
</div>
<div class="category_list" style="margin-bottom:24px;">
  <div class="product_class"><img src="@/img/product_picture/annual_inspection.png"/>年检代办</div>
  <div class="text_description">包含上门代办，站内代办服务。上门代办，免费专人上门取车，工作人员开车到指定检测站，全程代办，按时送还您的爱车；站内代办，您自驾到站交钥匙,全程1V1贴心代办服务。</div>
</div>
<div class="category_list" style="margin-bottom:24px;">
  <div class="product_class"><img src="@/img/product_picture/beerandskittles.png"/>吃喝玩乐</div>
  <div class="text_description">视频会员优惠开通、外卖会员优惠、话费优惠充值、各地特产采购等。</div>
</div>
</div>
  </div>
   </div>
 </template>
 <script>
 import { useRouter } from 'vue-router'
  //  import HelloWorld from './HelloWorld.vue'
 export default {
   data(){
    return {
      
    };
   },
   methods: {
    navigateTo(routePath) {
      // 使用编程方式进行路由跳转
      const router = useRouter()
      router.push(routePath)
    },
    
  }
}
 </script>
 
 <style scoped>
 /* 大盒子 */
 .common-layout{
   width:100%;

 }
 /* 图片 */
 .home{
   overflow: hidden;
   width: 100%;
 }
 .home >img {
   width: 100%;
   height:680px;
   object-fit: cover; /* 避免图片变形 */
     display: block;
   margin: 0;
   padding: 0;
   border: none;
 }
 /* 主要内容 */
 .main_zone{
   width:1200px;
    margin: 0 auto;
  justify-content: space-between;
  background-color: #FFFFFF;
 }
 /* 导航栏 */
 .navigation{
  width: 1200px;
  height: 129px;
  margin: 0 auto;
  display: flex;
  font-family: 'Microsoft YaHei';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 129px;
 }
 .el-breadcrumb{
  display: flex;
}
.el-breadcrumb ::v-deep .el-breadcrumb__inner  { 
  --el-color-primary:  #F14F44; }
 .myColor:nth-of-type(1)  ::v-deep .el-breadcrumb__inner {
  font-family: 'Microsoft YaHei';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 21px;
}
.myColor:nth-of-type(2)  ::v-deep .el-breadcrumb__inner {
  font-family: 'Microsoft YaHei';
font-style: normal;
font-weight: 400;
font-size: 16px;
color: #292C2C;

}
/* 自有产品 */
.product_display{
  width: 100%;
  background-color:  #FFFFFF;
  height:732px;
  padding-bottom:120px;
}
.el-header {
  font-family: 'Microsoft YaHei';
font-style: normal;
font-weight: 700;
font-size: 28px;
line-height: 32px;
color: #292C2C;
margin-bottom: 40px;
padding-left: 0;
position:relative;
display: flex;
  align-items: center; 
}
.headline,.icon{
  display: inline-block;
}
.official_accounts{
  font-family: 'Microsoft YaHei';
font-style: normal;
font-weight: 400;
font-size: 24px;
line-height: 32px;
color: #292C2C;
display: flex;
/* padding-bottom: 24px; */
height: 56px;
}
.el-aside{
  width:540px;
  height: 584px;
  background-color:#FAFAFA;
  text-indent: 20px;
  padding:20px;
  margin-right: 28px;
}
.explain{
  font-family: 'Microsoft YaHei';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 180%;
color: #292C2C;
display: block; 
padding-top:35px;
}
.el-main{
  width:630px;
  height:584px;
  background-color:#FFFFFF;
  padding: 0;
  /* margin-left: 28px; */
  display: flex;
  overflow-x: hidden;
}
.el-main img{
  object-fit: cover; /* 避免图片变形 */
  display: block;
  width:202px;
  height:454.44px;
  border-radius:24px;
border: 6px solid var(--unnamed, #545454);
background: lightgray 50% / cover no-repeat;
}
.el-main>img:nth-of-type(1){
  margin-top: 117px;
}
.el-main>img:nth-of-type(2){
margin: 44px -6px 0 -6px;
}
/* 九华保产品 */
.category{
width:1200px;
  height: 908px;
  padding: 0 20px;
}
.category_list{
  height: 142px;
  background-color:  #FAFAFA;
  padding:0 20px;
}
.category_list:nth-of-type(5){
  height: 188px;
  background-color:  #FAFAFA;
}
.product_class{
  height:52px;
  display: flex;
flex-direction: row;
align-items: center;
padding: 0px 0px 4px;
gap: 12px;
padding-top: 20px;
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 26px;
border-bottom:2px solid #FAFAFA;
box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
}
.product_class>img{
  width: 48px;
  height:48px;
  object-fit: cover; /* 避免图片变形 */
  display: block;
}
.text_description{
  font-family: 'Microsoft YaHei';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 26px;
padding-top: 24px;
color: #000000;
}

 </style>
 