<template>
  <div class="common-layout">
 
       <div class="home">
         <img src="@/img/AboutUs.png" />
       </div>
       <!-- 主要内容 -->
     <div class="navigation">
        您当前位置：
        <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }" class="myColor">共幸科技</el-breadcrumb-item>
      <el-breadcrumb-item class="myColor" >关于我们</el-breadcrumb-item>
    </el-breadcrumb> 
</div>
 <div class="main_zone">
  <!-- 九华保 -->
  <div class="product_display">
    <el-container >
      <el-header>
        <i  class="headline" style="width:8px;height: 28px;background: linear-gradient(145.43deg, #FFAF9B 10.56%, #F14F44 91.33%);display:inline-block;margin-right: 12px;"></i>
        <div class="icon">公司简介</div>
      </el-header>
      <el-main style="height:223px;background-color: #FFFFFF;padding:0 20px;margin-bottom: 60px;">
        <ul>
          <li>保险生态服务专家</li>
          <li>共幸科技成立于2019年，公司主要面向国内各大保险公司提供企业产品宣传、车务增值服务等产品内容。</li>
          <li>共幸科技致力于为全国私家车主提供汽车后市场全产业链服务。</li>
          <li>共幸科技通过自研IVI智能系统，为汽车后市场中小企业提供平台运营及门店引流服务，从而达到互惠互利。</li>
        </ul>
      </el-main>
      <el-footer >
       <div class="dianegative">
       <img src="@/img/logo.png"/>
       </div>
       <div class="vision">
        <div class="vision_left">愿景</div>
       <div class="vision_right">
        <p class="vision_list">为品质车主提供高端供应链服务</p>
        <p class="vision_list">通过细分与聚焦车后服务领域，打造保险车服务后市场核心竞争力，聚焦品质车主提供智能车检等增值服务，通过数字手段、科技创新和平台运营为保险公司车险服务赋能。</p>
       </div>
       </div>
      </el-footer>
    </el-container>
    <!-- 发展历程 -->
       <el-header style="padding: 60px 0;">
        <i  class="headline" style="width:8px;height: 28px;background: linear-gradient(145.43deg, #FFAF9B 10.56%, #F14F44 91.33%);display:inline-block;margin-right: 12px;"></i>
        <div class="icon">发展历程</div>
      </el-header>
<!-- 时间线 -->
 <div class="container">
  <!-- position: absolute; -->
  <div style="display:flex; position: absolute;  margin-top: 40px;">
    <div class="item-wrap"  v-for="item in timelineItems" :key="item.id">
                <div class="item"></div>
                <div class="dot">
                  <div style="border-left:1px solid #B5B5B5;height:80px;margin: 14px 0 0 6px"></div>
                </div>
                <div class="item"></div>
                  <div class="tp">{{ item.date}}  </div>
                  <div class="bt" v-html="item.description"></div>
            </div>
  </div>
  </div>

</div>
  </div>
   </div>
 </template>
 <script>
 import { useRouter } from 'vue-router'
  //  import HelloWorld from './HelloWorld.vue'
 export default {
   data(){
    return {
    timelineItems: [
        {
          id: 1,
          date: '2019',
          description: '共幸科技成立<br/>IVI智检车系统上线'
        },
        {
          id: 2,
          date: '2020',
          description: 'CII供应链系统上<br/>线，实现客户、商<br/>户、订单、财务信<br/>息等全链路管理'
        },
        {
          id: 3,
          date: '2021',
          description: '九华保运营平台上线'
        },
        {
          id: 4,
          date: '2022',
          description: 'CII供应链系统2.0上线，创造引领数据价<br/>值服务'
        },
        {
          id: 5,
          date: '2023',
          description: '形成产业集群超<br/>30万注册用户&10<br/>万月活用户'
        },
      ]
    };
   },
   methods: {
    navigateTo(routePath) {
      // 使用编程方式进行路由跳转
      const router = useRouter()
      router.push(routePath)
    },
    
  }
}
 </script>
 
 <style lang="scss" scoped >
 /* 大盒子 */
 .common-layout{
  width:100%;
 }
 /* 图片 */
 .home{
   overflow: hidden;
   width: 100%;
 }
 .home >img {
   width: 100%;
   height:680px;
   object-fit: cover; /* 避免图片变形 */
     display: block;
   margin: 0;
   padding: 0;
   border: none;
 }
 /* 主要内容 */
 .main_zone{
   width:1200px;
   height: 1203px;
    margin: 0 auto;
  justify-content: space-between;
  background-color: #FFFFFF;
 }
 /* 导航栏 */
 .navigation{
  width: 1200px;
  height: 129px;
  margin: 0 auto;
  display: flex;
  font-family: 'Microsoft YaHei';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 129px;
 }
 .el-breadcrumb{
  display: flex;
}
// .el-breadcrumb /deep/ .el-breadcrumb__inner{ 
//   --el-color-primary: #F14F44; }
//  .myColor:nth-of-type(1) /deep/ .el-breadcrumb__inner {
//   font-family: 'Microsoft YaHei';
// font-style: normal;
// font-weight: 400;
// font-size: 16px;
// line-height: 21px;
// }
// .myColor:nth-of-type(2) /deep/ .el-breadcrumb__inner {
//   font-family: 'Microsoft YaHei';
// font-style: normal;
// font-weight: 400;
// font-size: 16px;
// color: #292C2C;

// }
/* 公司简介 */
.product_display{
  width: 100%;
  background-color:  #FFFFFF;
  height:732px;
  padding-bottom:120px;
}
.el-header {
  font-family: 'Microsoft YaHei';
font-style: normal;
font-weight: 700;
font-size: 28px;
line-height: 32px;
color: #292C2C;
margin-bottom: 40px;
padding-left: 0;
position:relative;
display: flex;
  align-items: center; 
}
.headline,.icon{
  display: inline-block;
}
/* 公司简介--保险 */
.el-main ul{
  list-style: none;
  // overflow: hidden;
}
.el-main li:nth-of-type(1){
  font-family: 'Microsoft YaHei';
font-style: normal;
font-weight: 400;
font-size: 24px;
line-height: 180%;
color: #292C2C;
padding-bottom:24px
}
.el-main li{
  font-family: 'Microsoft YaHei';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 180%;
color: #545454;
padding-bottom:24px
}
.el-main li:nth-of-type(4){
padding-bottom:0;
line-height: 90%;
}
.el-footer {
  height:295px;
  padding:0 48px;
  background: var(--unnamed, #FAFAFA);
      position: relative;
    overflow: hidden;
}
.dianegative{
   position: absolute;
   overflow: hidden;
   bottom: 40px;
   flex-shrink: 0;

}
.dianegative>img{
  display: block;
  // margin-left: -210px;/* 将图片向左移动 */
  // height:128px;
  width: 300px;
  opacity: 0.05000000074505806;
}
.vision{
  width:1104px;
  height:175px;
  z-index: 1;
  margin: 60px 0;
  display: flex;
}
.vision_left{
  color: #000;
font-size: 28px;
height:160px;
width: 361px;
font-family: Microsoft YaHei;
font-weight: 700;
border-right:1px solid #B5B5B5 ;
}
.vision_right{
  margin-left: 60px;
  width:695px;
}
.vision_list:nth-of-type(1) {
  color: var(--unnamed, #292C2C);
font-size: 24px;
font-family: Microsoft YaHei;
line-height: 180%;
padding-bottom:24px;
}
.vision_list:nth-of-type(2) {
  color: var(--unnamed, #545454);
font-size: 20px;
font-family: Microsoft YaHei;
line-height: 180%;
}
.container{
  // max-width:1160px;
            height: 288px;
            width: 1200px;
            // overflow-x: auto;
            overflow:hidden;
            position: relative;
         
         .item-wrap{
              font-size: 13px;
              position: relative;
              flex:1;display:flex;
             .tp{
                  position: absolute;
                  width: 100%;
                  bottom: 20px;
                  text-align: center;
                  color: var(--unnamed, #292C2C);
                  font-size: 24px;
                  font-family: Microsoft YaHei;     
              }
 
              .bt{
                  position: absolute;
                  width: 144px;
                  // margin-left: 40px;
                  top: 108px;
                  color: var(--unnamed, #545454);
                  font-size: 20px;
                  line-height: 180%; 
                  display: flex;
                  margin-left: 50px;
                  width: 200px;          
              }
          
         }
 
         .dot{
            border:2px solid #B5B5B5;
            width: 12px;
            height: 12px;
            border-radius: 12px;
            // background:#07c160; //white;
            margin: auto;
            margin-bottom: -4px;
            position: relative;
           
         }
        .item{
            flex:1;
            width: 112px;
            border-bottom:1px solid #B5B5B5;
        }
 
        }

 </style>
 