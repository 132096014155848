
import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
// 导入组件
import HelloWorld from '../components/HelloWorld.vue';
import Products from '../components/Products.vue';
import AboutUs from '../components/AboutUs.vue';
import sustain from '../components/sustain.vue';

// 创建路由实例
const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: '首页',
      component: HelloWorld
    },
    {
      path: '/Products',
      name: '产品',
      component: Products
    },
    {
      path: '/AboutUs',
      name: '关于我们',
      component: AboutUs
    },
    {
      path: '/sustain',
      name: '服务',
      component: sustain
    }
  ]
});

export default router;