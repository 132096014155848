<template>
  <div class="common-layout">
       <div class="home">
         <img src="@/img/serviceandsupport.png" />
       </div>
       <!-- 主要内容 -->
     <div class="navigation">
        您当前位置：
        <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }" class="myColor">共幸科技</el-breadcrumb-item>
      <el-breadcrumb-item class="myColor" >服务</el-breadcrumb-item>
    </el-breadcrumb> 
</div>
 <div class="main_zone">
  <!-- 服务与支持 -->
  <div class="product_display">
    <el-container >
      <el-header>
        <i  class="headline" style="width:8px;height: 28px;background: linear-gradient(145.43deg, #FFAF9B 10.56%, #F14F44 91.33%);display:inline-block;margin-right: 12px;"></i>
        <div class="icon">服务支持</div>
      </el-header>
     <!-- 版心图片 -->
      <el-main>
        <img src="@/img/sustain_img/serve.png"/>
      </el-main>
      <!-- 分割线 -->
      <div style="margin: 60px 50px ;border: 1px solid #B5B5B5;"></div>
      <el-footer >
        <div class="service_item" v-for="item in serviceitems" :key="item.id">
    <span class="service_list">
      <img :src="item.image" />
      {{ item.date}}</span >
    <span class="service_list">{{ item.description }}</span>
  </div>
      </el-footer>
    </el-container>


</div>
  </div>
   </div>
 </template>
 <script>
 import { useRouter } from 'vue-router'
  //  import HelloWorld from './HelloWorld.vue'
 export default {
   data(){
    return {
      serviceitems:[
           {
            id: 1,
          date: '7*24小时电话',
          description: '用户在业务执行过程中发生问题都可以立即拨打热线电话进行问题反馈，热线服务人员会根据问题类型确定合理的解决方案。',
          image: require('@/img/sustain_img/phoneq.png')
      
           },{
            id: 2,
          date: '现场支持',
          description: '委派专业工作人员现场协助用户处理故障，快速的服务响应与技术、系统开发支持服务，提高工作效率。',
          image: require('@/img/sustain_img/on_sitesupport.png')
           }
      ]
    };
   },
   methods: {
    navigateTo(routePath) {
      // 使用编程方式进行路由跳转
      const router = useRouter()
      router.push(routePath)
    },

    }
}
 </script>
 
 <style lang="scss" scoped >
 /* 大盒子 */
 .common-layout{
  width:100%;
 }
 /* 图片 */
 .home{
   overflow: hidden;
   width: 100%;
 }
 .home >img {
   width: 100%;
   height:680px;
   object-fit: cover; /* 避免图片变形 */
     display: block;
   margin: 0;
   padding: 0;
   border: none;
 }
 /* 主要内容 */
 .main_zone{
   width:1200px;
  //  height: 1662px;
    margin: 0 auto;
  justify-content: space-between;
  background-color: #FFFFFF;
 }
 /* 导航栏 */
 .navigation{
  width: 1200px;
  height: 129px;
  margin: 0 auto;
  display: flex;
  font-family: 'Microsoft YaHei';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 129px;
 }
 .el-breadcrumb{
  display: flex;
}
// .el-breadcrumb /deep/ .el-breadcrumb__inner{ 
//   --el-color-primary: #F14F44;}
//  .myColor:nth-of-type(1) /deep/ .el-breadcrumb__inner {
//   font-family: 'Microsoft YaHei';
// font-style: normal;
// font-weight: 400;
// font-size: 16px;
// line-height: 21px;
// // color: red;
// }
// .myColor:nth-of-type(2) /deep/ .el-breadcrumb__inner {
//   font-family: 'Microsoft YaHei';
// font-style: normal;
// font-weight: 400;
// font-size: 16px;
// color: #292C2C;

// }
.el-main{
  width:1200px;
  height:348px;
  padding:0;
  overflow: hidden;
}
.el-main>img{
  width: 100%;
  height:auto;
  object-fit: cover; /* 避免图片变形 */
  display: block;
}
/* 公司简介 */
.product_display{
  width: 100%;
  background-color:  #FFFFFF;
  // height:732px;
  padding-bottom:90px;
}
.el-header {
  font-family: 'Microsoft YaHei';
font-style: normal;
font-weight: 700;
font-size: 28px;
line-height: 32px;
color: #292C2C;
margin-bottom: 40px;
padding-left: 0;
position:relative;
display: flex;
  align-items: center; 
}
.headline,.icon{
  display: inline-block;
}
/* 公司服务 */
.el-footer {
  width:1200px;
  height:308px;
  padding: 0;
}
.service_list{
  display: flex;
  align-items: center;
  // height:102px;
}
.service_item{
height:142px;
background-color:#FAFAFA;
margin-bottom: 24px;
padding: 0 20px;
}
.service_list:nth-of-type(1){
  color: var(--unnamed, #292C2C);
font-size: 20px;
font-family: Microsoft YaHei;
font-weight: 700;
border-bottom: 1px solid #B5B5B5;
padding: 20px 0 4px 0;
}
.service_list:nth-of-type(2){
  color: #000;
font-size: 20px;
font-family: Microsoft YaHei;
padding-top: 24px;
}
.service_list>img{
  width:48px;
  height:48px;
  object-fit: cover; /* 避免图片变形 */
  display: block;
  margin-right: 10px; 
}

 </style>
 